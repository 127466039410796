.skillBanner {
  background: linear-gradient(to right, #00000000, #fff, #fff, #00000000),
    linear-gradient(#fff, #00000000, #fff), linear-gradient(#888, #888);
  padding: 10px;
}

.skills {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
  margin: 0 auto;
  justify-items: center;
  max-width: 1200px;
}

.skill {
  border: solid #555;
  margin: 30px;
  transition: ease-out 0.1s;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 320px;
  padding: 15px 10px;
}

.skill img {
  max-width: 100%;
}

.skill h3 {
  font-size: 1.5rem;
}

.skill a {
  text-decoration: none;
  color: black;
  transition: ease-out 0.1s;
}

.skill:hover {
  box-shadow: 0 0 10px 10px #aaaaaa90;
}

@media only screen and (max-width: 650px) {
  .skills {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .skill {
    width: 95%;
  }

  .skill h3,
  .skill p {
    font-size: 1rem;
  }
}
