@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
* {
  margin: 0;
  padding: 0;
  font-family: 'Josefin Sans', sans-serif;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

h1 {
  font-size: 2.5rem;
}

p {
  font-size: 1.2rem;
}

.socMeds {
  display: flex;
  justify-content: center;
}
.socMeds a {
  text-decoration: none;
  color: black;
  margin: 10px;
}

.socMeds svg {
  font-size: 3rem;
}

@media only screen and (max-width: 650px) {
  .socMeds svg {
    font-size: 2rem;
  }
  .Footer .socMeds svg {
    font-size: 1rem;
  }
}
#landingPage {
  height: 100vh;
  text-align: center;
  background: linear-gradient(rgba(252, 75, 26, 0.475), 
  rgba(168, 252, 26, 0.475)), 
  url(/static/media/tree_background.37d1ff6a.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#navbar {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-content: center;
  background: linear-gradient(to right, #00000000, #ffffff79);
  margin: 0 auto;
  z-index: 11;
  height: 61px; 
}

#navbar a {
  text-decoration: none;
  color: black;
  padding: 20px;
  transition: ease-out 0.1s;
}

#navbar a:hover, #havbar a:active {
  background-color: #ffffff79;
}

@media only screen and (max-width: 550px) {
  #navbar {
    display: grid;
    grid-template: auto auto / auto auto;
  }

  #navbar a {
    padding: 3px;

  }
}


#aboutSection {
  max-width: 800px;
  margin: 20px auto;
}

#aboutPage p {
  padding: 10px;
}

#aboutText a {
  text-decoration: none;
  color: rgb(160, 43, 11);
  transition: ease-out 0.1s;
}

#aboutText a:hover {
  text-decoration: underline;
}
.banner {
  width: 100%;
  background: rgba(252, 75, 26, 1);
  text-align: center;
  padding: 15px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  margin: 30px 0;;
}

@media only screen and (max-width: 975px) {
  .banner {
    text-align: left;
    padding-left: 10%;
  }
}

@media only screen and (max-width: 500px) {
  .banner {
    padding-left: 5px;
  }
}

.skillBanner {
  background: linear-gradient(to right, #00000000, #fff, #fff, #00000000),
    linear-gradient(#fff, #00000000, #fff), linear-gradient(#888, #888);
  padding: 10px;
}

.skills {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
  margin: 0 auto;
  justify-items: center;
  max-width: 1200px;
}

.skill {
  border: solid #555;
  margin: 30px;
  transition: ease-out 0.1s;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 320px;
  padding: 15px 10px;
}

.skill img {
  max-width: 100%;
}

.skill h3 {
  font-size: 1.5rem;
}

.skill a {
  text-decoration: none;
  color: black;
  transition: ease-out 0.1s;
}

.skill:hover {
  box-shadow: 0 0 10px 10px #aaaaaa90;
}

@media only screen and (max-width: 650px) {
  .skills {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .skill {
    width: 95%;
  }

  .skill h3,
  .skill p {
    font-size: 1rem;
  }
}



.Footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: #888;
  padding: 25px;
  text-align: center;
}

.Footer a {
  text-decoration: none;
  color: rgb(160, 43, 11);
  transition: ease-out 0.1s;
}

.Footer a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 550px) {
  .Footer {
    flex-direction: column;
  }
}
