.banner {
  width: 100%;
  background: rgba(252, 75, 26, 1);
  text-align: center;
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 10;
  margin: 30px 0;;
}

@media only screen and (max-width: 975px) {
  .banner {
    text-align: left;
    padding-left: 10%;
  }
}

@media only screen and (max-width: 500px) {
  .banner {
    padding-left: 5px;
  }
}