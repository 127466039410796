.App {
  text-align: center;
}

h1 {
  font-size: 2.5rem;
}

p {
  font-size: 1.2rem;
}
