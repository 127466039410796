#navbar {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-content: center;
  background: linear-gradient(to right, #00000000, #ffffff79);
  margin: 0 auto;
  z-index: 11;
  height: 61px; 
}

#navbar a {
  text-decoration: none;
  color: black;
  padding: 20px;
  transition: ease-out 0.1s;
}

#navbar a:hover, #havbar a:active {
  background-color: #ffffff79;
}

@media only screen and (max-width: 550px) {
  #navbar {
    display: grid;
    grid-template: auto auto / auto auto;
  }

  #navbar a {
    padding: 3px;

  }
}

