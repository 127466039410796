
.Footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: #888;
  padding: 25px;
  text-align: center;
}

.Footer a {
  text-decoration: none;
  color: rgb(160, 43, 11);
  transition: ease-out 0.1s;
}

.Footer a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 550px) {
  .Footer {
    flex-direction: column;
  }
}