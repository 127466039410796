.socMeds {
  display: flex;
  justify-content: center;
}
.socMeds a {
  text-decoration: none;
  color: black;
  margin: 10px;
}

.socMeds svg {
  font-size: 3rem;
}

@media only screen and (max-width: 650px) {
  .socMeds svg {
    font-size: 2rem;
  }
  .Footer .socMeds svg {
    font-size: 1rem;
  }
}